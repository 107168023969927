import Container from '@material-ui/core/Container';
import { Component } from 'react';

import './VideoYoutube.scss';

class VideoYoutube extends Component {
    render(){
        return(
            <Container maxWidth="lg">
                <div className="conteiner-video-youtube">
                    <iframe className="embed-responsive-item" src={this.props.urlVideo} title="YT video"></iframe>
                </div>
            </Container>
        )
    }
}
export default VideoYoutube