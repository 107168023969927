import { Component } from 'react';

import Menu from '../../PagesComponents/Menu/Menu';
import Footer from '../../PagesComponents/Footer/Footer';
import Info from '../../PagesComponents/Info/Info';

import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import QuadrifoglioGroupSection from './../../PagesComponents/Pages/QuadrifoglioGroup/QuadrifoglioGroup';
import {
  Link, withRouter
} from "react-router-dom";

import './QuadrifoglioGroup.scss';

class QuadrifoglioGroup extends Component {

  render() {
    return (
      <div className="quadrifoglio-group">

        <Menu />

        <Banner
          bannerImage={'top-dettaglio-x10-quadrifoglio.jpeg'}
          name={'QUADRIFOGLIO'}
          inner={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/">
                Inicio
              </Link>
              <Link color="inherit" to="/quadrifoglio-grupo">
                Empresa
              </Link>
            </Breadcrumbs>
            }  
          />
        <QuadrifoglioGroupSection/>

        <Info  />
        <Footer />
      </div>
    );
  }
}

export default withRouter(QuadrifoglioGroup);