import { Component } from 'react';

import Container from '@material-ui/core/Container';
import renderHTML from 'react-render-html';
import { unescapeHTML } from './../../../../../utils/formatText';

import './MainInfo.scss';

class MainInfo extends Component {

    render() {
        return (
            <Container maxWidth="lg">
                <div className="conteiner-main-info">
                    <div className="conteiner-info">
                        {this.props.description && (
                            <p>
                                {renderHTML(unescapeHTML(this.props.description))}
                            </p>
                        )}
                    </div>
                    <div className="conteiner-img-info">
                        {this.props.img && (
                            <img src={`/images/${this.props.img}`} alt="" srcSet="" />
                        )}
                    </div>
                </div>
            </Container>
        );
    }
}

export default MainInfo;