import { Component } from 'react';

import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Filters from './../../PagesComponents/Pages/Catalog/Filters/Filters';
import ProductsList from './../../PagesComponents/Pages/Products/ProductsList/ProductsList';

import './Catalog.scss';

class Catalog extends Component {

  render() {
    return (
      <div className="catalog-component">
        <div className="container-section">
            <div className="header-catalog">
                <Container maxWidth="xl">
                    <div>
                        <h1>
                            EXPLORE NUESTRO CATÁLOGO
                        </h1>
                    </div>
                    <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/">
                            Home
                        </Link>
                        <Link color="inherit" href="/catalogo">
                            Productos
                        </Link>
                    </Breadcrumbs>
                    </div>
                </Container>
            </div>
            <Filters/>
            <ProductsList/>
        </div>
      </div>
    );
  }
}

export default Catalog;