import { Component } from 'react';

import Container from '@material-ui/core/Container';

import './Banner.scss';

class Banner extends Component {
  render() {
    return (
      <div className="select-banner">
        <div className="image-banner" style={{ backgroundImage: `url("/images/banners/${this.props.bannerImage}")` }}>
          <div className="inner-background"></div>
          <div className="container-text">
            <Container maxWidth="lg">
              {this.props.name &&
                <div className="title-main">
                  {this.props.name}
                </div>
              }
              {this.props.inner}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}


export default Banner;