import React, { Component, Fragment } from 'react';
import renderHTML from 'react-render-html';
import './Dynamic.scss';

// const styles = {
//     title: {
//     display: 'flex',
//     justifyContent: 'center',
//     },
//     image: {
//     width: '100%',
//     borderRadius: '10px',
//     },
//     par: {
//       marginTop: '15px',
//       marginBottom: '15px',
//     },
// }

class Dynamic extends Component {

  unescapeHTML(escapedHTML) {
    return escapedHTML.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
  }

  render() {
    return (
      <Fragment>
        <div className="note-component">
          {(this.props.post && this.props.post.text) && (
            <div>
              { renderHTML(this.unescapeHTML(this.props.post.text ? this.props.post.text : '')) }
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Dynamic;