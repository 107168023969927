import { Component } from 'react';

import Container from '@material-ui/core/Container';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import './Quadrifoglio.scss';

class QuadrifoglioSection extends Component {

  render() {
    return (
      <div className="quadrifoglio-group-section">
        <Container maxWidth="sm">
          <div className="description">
            18.000 personalizaciones de producto gracias a la amplia gama de productos y colecciones propuestas por nuestras marcas.
          </div>
        </Container>
        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-left">
              <h2>
                <img src="/images/banners/brand-quadrifoglio2.png" alt="logo" />
              </h2>
              <p>
                Quadrifoglio Group diseña y fabrica muebles de oficina, desarrollando un nuevo concepto de estilo con detalles y materiales refinados.
                Un extenso catálogo para múltiples soluciones de oficina y contract. Quadrifoglio fabrica mesas direccionales y puestos de trabajo, asientos, recepción, salas de reuniones, compartimentación de mampara, almacenaje y accesorios.
                La ergonomía, la calidad y la atención a la persona se encuentran en el centro del proceso de diseño y producción de los productos del Grupo Quadrifoglio, 100% made in Italy.
              </p>
            </div>
            <div className="image-right">
            <Carousel
                autoPlay={true}
                interval={2000}
                infiniteLoop={true}
                emulateTouch={true}>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775512-quadrifoglio-group-brand-Avana-8.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775560-quadrifoglio-group-brand-X9-9.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775587-quadrifoglio-group-brand-X5-10.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775510-quadrifoglio-group-brand-Idea-Creo-11.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775618-quadrifoglio-group-brand-X-Change-1.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775615-quadrifoglio-group-brand-City-2.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775605-quadrifoglio-group-brand-Z1-3.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775465-quadrifoglio-group-brand-Arco-4.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775566-quadrifoglio-group-brand-XW-P1-5.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775454-quadrifoglio-group-brand-Agio-6.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612775456-quadrifoglio-group-brand-Code-7.jpeg)'
                    }}>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </Container>

        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-right">
              <h2>
                <img src="/images/banners/brand-karboxx.png" alt="logo-karboxx" />
              </h2>
              <p>
                La investigación sobre formas y materiales como la fibra de carbono y la fibra de vidrio inspira el concepto de diseño y luz para Karboxx. La tecnología de vanguardia combinada con materiales nobles ofrece colaboraciones con arquitectos reconocidos y diseñadores emergentes.
                <br />
                Dos colecciones, Decorativa y Oficina, para iluminar ambientes dirigidos al público y al mundo de la oficina, con especial atención a las necesidades estéticas y profesionales contemporáneas.
                <br />
                Karboxx utiliza lo mejor de la tecnología de iluminación, perfeccionada en laboratorios de investigación, para lograr el máximo bienestar, administrando el confort tanto visual como acústico.
              </p>
            </div>
            <div className="image-left">
              <Carousel
                autoPlay={true}
                interval={2000}
                infiniteLoop={true}
                emulateTouch={true}>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/babel-lamp-karboxx-decorative.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/balance-lamp-karboxx-decorative-quadrifoglio.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1490917776-karboxx_thequadrifogliogroup_1.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/lightsound-acoustic-system-karboxx.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/eustress-lamp-karboxx-office.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/fly-lamp-karboxx-office.jpeg)'
                    }}>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default QuadrifoglioSection;