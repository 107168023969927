import { Component } from 'react';

import {
  Link
} from "react-router-dom";

import Menu from '../../PagesComponents/Menu/Menu';
import Container from '@material-ui/core/Container';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Typography from '@material-ui/core/Typography';

import ReCAPTCHA from "react-google-recaptcha";

import MainInfo from '../../PagesComponents/Pages/Products/MainInfo/MainInfo';
import Info from '../../PagesComponents/Info/Info'
import Footer from '../../PagesComponents/Footer/Footer';

import './Contact.scss';

class Contact extends Component {

  state = {
    email: '',
    name: '',
    description: '',
    checkedMap: false,
    openMessage: false,
    messageAlert: '',
    iconMessage: '',
    recatcha: false,
    product: {}
  }

  showMessage(message, icon) {
    this.setState({
      openMessage: true,
      messageAlert: message,
      iconMessage: icon
    });
  };

  hideMessage() {
    this.setState({
      openMessage: false
    });
  };


  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  async onChangeReCAPTCHA (ref, value) {
    ref.setState({
      recatcha: true
    });
  };

  sendEmail() {
    console.log({
      recatcha: this.state.recatcha, email: this.state.email, description: this.state.description
    });
    if (this.state.recatcha && this.state.email !== '' && this.state.description !== '') {
      var formData = new FormData();
      formData.append('action', 'correo');
      formData.append('email', this.state.email);
      formData.append('name', this.state.name);
      formData.append('message', this.state.description);

      fetch(`${global.config.URL.SERVER}/sendEmail`, {
        method: 'POST',
        body: formData
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            email: '',
            description: '',
            name: ''
          });
          this.showMessage('Mensaje enviado', <CheckCircleIcon className="mr-2" />);
        } else {
          this.showMessage('Error al enviar el mensaje', <ErrorIcon className="mr-2" />);
        }
      })
      .catch(error => {
        console.log(error);
        this.showMessage('Error al enviar el mensaje', <ErrorIcon className="mr-2" />);
      });
    }
  }


  render() {
    return (
      <div className="contact-component">

        <Menu />

        <div className="container-banner">
          <Banner
            name={'Contáctanos'}
            inner={
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  Inicio
                </Link>
                <Typography color="textPrimary">Contáctanos</Typography>
              </Breadcrumbs>
              }

            bannerImage = {'storia.jpg'}
          />
        </div>
      
        <MainInfo 
          img={(this.state.imgMain && this.state.imgMain.img) ? this.state.imgMain.img : null }
          description={this.state.product.description}
          />
        
        <Container maxWidth="lg" className="mb-10">
          <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6571.824620965468!2d-103.45185774475857!3d20.56838474231949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ab55ec0d9f77%3A0xf9cb9e5a852a7f16!2sAv.%20Punto%20Sur%20312%2C%2045645%20Los%20Gavilanes%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1629422487832!5m2!1ses-419!2smx" className="map" frameBorder="0" style={{border: 0}} allowFullScreen="1" aria-hidden="false" tabIndex="0" title="Requiez Mapa"></iframe>
          </div>
          <div className="mt-10">
            <h2 className="contact-title">¡ENVÍANOS UN MENSAJE!</h2>
          </div>
          <div className="container-contact">
            <div>
              <form noValidate autoComplete="off">
                <TextField
                  className="input-form w-100"
                  label="Mensaje"
                  name="description"
                  multiline
                  rowsMax={4}
                  value={this.state.description}
                  onChange={this.handleChange.bind(this)}
                />
                <TextField
                  className="input-form w-100"
                  label="Nombre"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange.bind(this)}
                />
                <TextField
                  className="input-form w-100"
                  label="Correo"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                />
                <div>
                  <ReCAPTCHA
                    ref={(r) => this.recaptcha = r}
                    sitekey="6Le4RWgaAAAAAPSMk2bLibrv1iEtzWpS_4VD_d68"
                    onChange={(value) => this.onChangeReCAPTCHA(this, value)}
                  />
                </div>
                <div className="text-right container-btn-send">
                  <Button className="btn-submit" onClick={this.sendEmail.bind(this)}>
                    Enviar
                  </Button>
                </div>
              </form>
            </div>
            <div>
              <div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    {/* <i className="ti-home"></i> */}
                    <HomeIcon className="ti-home" />
                  </span>
                  <div className="media-body">
                    <h3>
                      GRUPO REQUIEZ, S.A. DE C.V. <br />
                      Avenida Paseo Punto Sur No. 312, <br />
                      Nivel 1, Interior V2 <br />
                      Corporativo Punto Sur <br />
                      Tlajomulco De Zuñiga, Jalisco <br />
                      CP 45645
                    </h3>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <PhoneIphoneIcon className="ti-home" />
                  </span>
                  <div className="media-body">
                    <h3>+52 3338337788</h3>
                    <p>Lunes a Viernes 9:00 am a 6:00 pm</p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <EmailIcon className="ti-home" />
                  </span>
                  <div className="media-body">
                    <h3>hola@gruporequiez.com</h3>
                    <p>Envíanos un mensaje en cualquier momento!</p><p>
                  </p></div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Info />
        <Footer/>
        <Snackbar
          open={this.state.openMessage}
          onClose={this.hideMessage.bind(this)}
          onClick={this.hideMessage.bind(this)}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              {this.state.iconMessage}
              {this.state.messageAlert}
            </span>
          }
        />
      </div>
    );
  }
}

export default Contact;