import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import {
  Link, withRouter
} from "react-router-dom";

import Menu from '../../PagesComponents/Menu/Menu';
import Footer from '../../PagesComponents/Footer/Footer';
import Info from '../../PagesComponents/Info/Info';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectNote from './../../PagesComponents/GeneralComponents/SelectNote/SelectNote';

import './Blog.scss';

class Blog extends Component {

  state = {
    posts: [],
    page: 0,
    loading: false,
    loadMore: true
  };

  componentDidMount() {
    this.getAllPosts();
  }

  nextPosts() {
    this.getAllPosts();
  }


  getAllPosts() {
    this.setState({
      loading: true
    });
    fetch(`${global.config.URL.SERVER}/postsByPage/${this.state.page + 1}`, { 
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        const postList = data.map((post) => ({
            title: post.title ? post.title : '',
            createdBy: post.created_by ? post.created_by : '',
            createdSite: post.created_site ? post.created_site : '',
            summary: post.summary ? post.summary : '',
            url: post.url ? post.url : '',
            socialNetworkImage: post.socialNetworkImage ? post.socialNetworkImage : '',
          })
        );
        this.setState({
          page: this.state.page + 1,
          posts: [
            ...this.state.posts,
            ...postList
          ],
          loading: false,
          loadMore: (data.length > 0) ? true : false
        });
      }
    })
    .catch( err => {
      console.log(err);
    });
  }

  render() {
    return (
      <div className="blog-component">
        {/* <div className={`loadView ${(this.state.loadView) ? 'show' : ''}`}></div> */}
        {/* <Header/> */}

        <Menu />

        <div className="container-banner">
          <Banner inner={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/">
                Inicio
              </Link>
              <Typography color="textPrimary">Blog</Typography>
            </Breadcrumbs>
            }

            bannerImage = {'top-lampada-sospezione-zero-karboxx.jpg'}
          />
        </div>
        
        <div className="container-news-list">
          <Container maxWidth="lg" className="mb-10">
              {this.state.posts.map((value, index) => {
                return <div
                  className="mb-5"
                  key={index}>
                  <SelectNote
                    title={value.title}
                    summarize={value.summary}
                    urlImg={value.socialNetworkImage}
                    url={value.url}/>
                </div>;
              })}

              { !this.state.loading && this.state.loadMore && 
                <div
                  className="all-note text-center"
                  data-aos="fade-up"
                  data-aos-once="true" >
                  <Button className="btn-all-note" color="primary" variant="contained" onClick={this.nextPosts.bind(this)}>
                    Ver más
                  </Button>
                </div>
              }

              { this.state.loading && 
                <div className="text-center">
                  <CircularProgress />
                </div>
              }
          </Container>
        </div>

        <Info  />
        <Footer />
      </div>
    );
  }
}

export default withRouter(Blog);