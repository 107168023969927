import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Admin from './Components/Pages/Admin/Admin';
import Home from "./Components/Pages/Home/Home";
import Product from "./Components/Pages/Product/Product";
import Blog from "./Components/Pages/Blog/Blog";
import Note from "./Components/Pages/Note/Note";
import AtencionCliente from "./Components/Pages/AtencionCliente/AtencionCliente";
import Catalog from "./Components/Pages/Catalog/Catalog";
import QuadrifoglioGroup from './Components/Pages/QuadrifoglioGroup/QuadrifoglioGroup';
import Quadrifoglio from './Components/Pages/Quadrifoglio/Quadrifoglio';
import ProductsList from "./Components/PagesComponents/Pages/Products/ProductsList/ProductsList";
import SostenibilidadCert from "./Components/Pages/SostenibilidadCert/SostenibilidadCert";
import Patrimonio from './Components/Pages/Patrimonio/Patrimonio';
import Iframe from './Components/PagesComponents/Iframe/Iframe';
import Contact from './Components/Pages/Contact/Contact';
import About from './Components/Pages/About/About';

import SsbContainer from './Components/PagesComponents/GeneralComponents/SsbContainer/SsbContainer';

import './App.scss';
import Certifications from './Components/Pages/Certifications/Certifications';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'light',
          primary: {
            main: '#333'
          },
        },
      }),
    [prefersLightMode],
  );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/blog/:idBlog" children={
              <div>
                <ScrollToTop />
                <Note />
              </div>
            } />

            <Route path="/producto/:urlProduct" children={
              <div>
                <ScrollToTop />
                <Product />
              </div>
            } />
    
            <Route path="/admin">
              <Admin />
            </Route>
            <Route exact path="/sostenibilidad-y-certificados">
              <ScrollToTop />
              <SostenibilidadCert />
            </Route>
            <Route exact path="/grupo-requiez">
              <ScrollToTop />
              <About />
            </Route>
            <Route exact path="/productos">
              <ScrollToTop />
              <ProductsList />
            </Route>
            <Route exact path="/catalogo">
              <ScrollToTop />
              <Catalog />
            </Route>
            <Route exact path="/quadrifoglio">
              <ScrollToTop />
              <Quadrifoglio />
            </Route>
            <Route exact path="/quadrifoglio-grupo">
              <ScrollToTop />
              <QuadrifoglioGroup />
            </Route>
            <Route exact path="/atencionCliente">
              <ScrollToTop />
              <AtencionCliente />
            </Route>
            <Route exact path="/patrimonio">
              <ScrollToTop />
              <Patrimonio />
            </Route>
            <Route path="/blog" children={
              <div>
                <ScrollToTop />
                <Blog />
              </div>
            } />
            <Route path="/contacto" children={
              <div>
                <ScrollToTop />
                <Contact />
              </div>
            } />
            <Route exact path="/experiencia-virtual">
              <ScrollToTop />
              <Iframe />
            </Route>
            <Route exact path="/certificaciones">
              <ScrollToTop />
              <Certifications />
            </Route>
            <Route exact path="/">
              <ScrollToTop />
              <Home />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
