import { Component } from 'react';

import Container from '@material-ui/core/Container';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid';

import './SostenibilidadCertSection.scss';

class SostenibilidadCertSection extends Component {

  render() {
    return (
      <div className="quadrifoglio-group-section">
        <Container maxWidth="lg">
          <div className="description">
            Quadrifoglio Group, fundada en 1991, diseña y fabrica soluciones dedicadas a la vida en la oficina, mejorando la experiencia en el lugar de trabajo. Todas las soluciones propuestas tienen como objetivo no solo crear excelencia, sino también crear hábitos sostenibles en nuestra vida laboral.
          </div>
        </Container>
        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-left">
              <h2>CALIDAD</h2>
              <div className="line80w"></div>
              <br />
              <p>
                El primer objetivo de Quadrifoglio Group es la búsqueda continua del mejor servicio y del mejor producto, no solo para los clientes, sino también para todo el ciclo de producción, con total respeto con los principales estándares de calidad.
              </p>
            </div>
            <div className="image-right">
              <img src="images/quadrifoglio-group-sustainability-and-quality(1).jpg" alt="Quadrifoglio" />
            </div>
          </div>
          <div className="description-info-section-1 text-center">
            Quadrifoglio Sistemi d'Arredo cuenta con la certificación ISO 9001: 2015 para la calidad de los procesos y servicios relacionados con el trabajo. Nuestros productos son probados por CATAS, lo que garantiza su calidad según los parámetros vigentes. La empresa también está certificada por Office Excellence y garantiza la calidad de sus productos.
          </div>
          <div className="icons-info-section-1">
            <span>
              <img src="images/ManagementSysCert_ISO9001_14001_45001_ENG.png" alt="" />
            </span>
            <span>
              <img src="images/1581590623-oec.png" alt="" />
            </span>
            <span>
              <img src="images/1581590623-catas.jpg" alt="" />
            </span>
          </div>
        </Container>

        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-right">
              <h2>MEDIO AMBIENTE</h2>
              <div className="line80w"></div>
              <br />
              <p>
                El respeto por el mundo en que vivimos es una prioridad para el futuro de todos.
                <br />
                Es por esto que Quadrifoglio sigue estándares internacionales que garantizan el origen de las materias primas y la seguridad sanitaria.
                <br />
                Las certificaciones también se refieren a los procesos de producción, que reducen el impacto ambiental tanto mediante el uso de paneles de madera 100% ecológicos como con la instalación de paneles solares en su propia sede italiana.
              </p>
            </div>
            <div className="image-left">
              <div className="image-column">
                <span>
                  <img src="images/quadrifoglio-group-sustainability-office-living-wellness.jpg" alt="Quadrifoglio" />
                </span>
                <span>
                  <img src="images/1581590195-quadrifoglio-group-sustainability-ecologic-wood-panels.jpg" alt="Quadrifoglio" />
                </span>
              </div>
            </div>
          </div>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12} sm={6} >
                  <div>
                    <div class="about-center2">
                      <p>Quadrifoglio Sistemi d'Arredo está certificada en:</p>
                      <ul>
                        <li>ISO 14001: 2015 para el sistema de gestión ambiental</li>
                        <li>FSC<sup>TM</sup>&nbsp;para garantizar la trazabilidad de los materiales que provienen de áreas forestales certificadas&nbsp;(solicita más informaciones sobre nuetros productos certificados FSC<sup>TM</sup>)</li>
                        <li>Panel ecológico de madera 100% reciclado que respeta los niveles de emisión de formaldehído</li>
                      </ul>
                      <p>La empresa utiliza:</p>
                      <ul>
                        <li>CAM (criterios ambientales mínimos) para reducir el impacto ambiental y buscando métodos de producción respetuosos con el medio ambiente</li>
                        <li>CARB, una regulación estadounidense destinada a reducir y controlar las emisiones de formaldehído de los materiales a base de madera</li>
                        <li>Gestión del ciclo de vida del producto y reciclaje de artículos con un bajo impacto ambiental en colaboración con Valdelia en Francia</li>
                      </ul>
                      <p>La oficina central utiliza:</p>
                      <ul>
                        <li>Sistema fotovoltaico</li>
                        <li>Gestión de residuos</li>
                        <li>Sistema de termorregulación</li>
                        <li>Botellas de acero inoxidable en lugar de botellas de plástico</li>
                      </ul>
                      <ul>
                      </ul>                </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <div>
                    <video autoPlay={true} muted={true} loop={true} id="myVideo">
                      <source src="video/group-sustainable-and-ecologic-business.mp4" type="video/mp4" />
                      <source src="video/group-sustainable-and-ecologic-business.webm" type="video/webm" />
                      <source src="video/group-sustainable-and-ecologic-business.ogg" type="video/ogg" />
                    </video>
                  </div>
                </Grid>
              </Grid>
              <div className="icons-info-section-1">
                <img src="images/ManagementSysCert_ISO9001_14001_45001_ENG.png" alt="" />
                <img src="images/1581600281-ecological-panel.jpg" alt="" />
                <img src="images/1581600281-FSC.png" alt="" />
                <img src="images/Logo-Adherent-Coul.jpg" alt="" />
              </div>
            </Container>
          </div>
        </Container>
      
        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-left">
              <h2>SEGURIDAD</h2>
              <div className="line80w"></div>
              <br />
              <p>
                El objetivo de la empresa no es solo producir productos de calidad y respetar el medio ambiente, sino también garantizar la seguridad de todos los colaboradores, que invierten su tiempo persiguiendo estos objetivos todos los días.
              </p>
            </div>
            <div className="image-right">
              <img src="images/quadrifoglio-group-sustainability-workplace-safety.jpg" alt="Quadrifoglio" />
            </div>
          </div>
          <div className="icons-info-section-1">
            <span>
              <img src="images/ManagementSysCert_ISO9001_14001_45001_ENG.png" alt="" />
            </span>
          </div>
        </Container>

        {/* <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-left">
              <h2>EL BIENESTAR LABORAL</h2>
              <div className="line80w"></div>
              <br/>
              <p>
                Posturas e instrumentos de trabajo pueden mejorar nuestro rendimiento, protegiendo nuestra salud y nuestro estado de ánimo. Quadrifoglio Group trabaja en contacto continuo con Diseñadores y Proyectistas que poseen esta sensibilidad, innovando y buscando soluciones que unan estética y diseño, sostenibilidad empresarial y respeto del medio ambiente, asistencia completa a la calidad de nuestros productos.
              </p>
            </div>
            <div className="image-right">
              <div
                className="img-slider"
                style={{
                  'backgroundImage': 'url(/images/banners/corporate_quadrifoglio_benessere_lavorativo.jpeg)'
                }}>
              </div>
            </div>
          </div>
        </Container> */}

        {/* <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-right">
              <h2>SOSTENIBILIDAD Y RESPETO POR EL MEDIO AMBIENTE</h2>
              <div className="line80w"></div>
              <br/>
              <p>
                Nos interesa mucho el medio ambiente en el que vivimos, por eso Quadrifoglio Group ha decidido actuar con una política responsable para preservar la naturaleza y favorecer un desarrollo sostenible. Nuestro compromiso está certificado en la seguridad del producto y del medio ambiente (ISO) en el uso responsable de los recursos FSC®. Conscientes de la necesidad de un mundo mejor, la nueva sede corporativa estrenada en 2019 ha sido proyectada con especial cuidado en relación a la conservación del medio ambiente y a los principios de sostenibilidad. Queremos contribuir a aumentar la calidad del trabajo y, como consecuencia, mejorar la calidad de la vida.
              </p>
            </div>
            <div className="image-left">
              <Carousel
                autoPlay={true}
                interval={2000}
                infiniteLoop={true}
                emulateTouch={true}>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776115-quadrifoglio-group-sustainability-1.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776127-quadrifoglio-group-sustainability-2.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776142-quadrifoglio-group-sustainability-3.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776141-quadrifoglio-group-sustainability-4.jpeg)'
                    }}>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </Container> */}
      </div>
    );
  }
}

export default SostenibilidadCertSection;