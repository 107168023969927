import React, { Component } from 'react';
import Container from '@material-ui/core/Container';


import './Footer.scss';
class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <Container maxWidth="lg">
                    <div className="footer__bottom">
                        <div className="footer__bottom-left">
                            <a href="https://www.google.com.mx/maps/place/Av.+Punto+Sur+312,+45645+Los+Gavilanes,+Jal./@20.5683847,-103.4518577,16.19z/data=!4m5!3m4!1s0x8428ab55ec0d9f77:0xf9cb9e5a852a7f16!8m2!3d20.5686997!4d-103.4522209" target="_blank" rel="noopener noreferrer">
                                GRUPO REQUIEZ, S.A. DE C.V. <br />
                                Avenida Paseo Punto Sur No. 312, <br />
                                Nivel 1, Interior V2 <br />
                                Corporativo Punto Sur <br />
                                Tlajomulco de Zuñiga, Jalisco <br />
                                CP 45645
                            </a>
                        </div>
                        <div className="footer__bottom-center">
                            <div>
                                <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a>
                            </div>
                            <div>
                                <a href="tel:+523338337788">+52 (33) 3833 7788</a>
                            </div>
                        </div>
                        <div className="footer__bottom-right">
                            <div>
                                <a href="/files/CodigoEticaGrupoRequiez.pdf" target="_blank">Código de ética</a>
                            </div>
                            <div>
                                <a href="/files/AvisoPrivacidad.pdf" target="_blank">Aviso de privacidad</a>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Footer