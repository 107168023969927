import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import './Patrimonio.scss';

class Patrimonio extends Component {

  render() {
    return (
      <div className="patrimonio-section">
        <Container maxWidth="xl">
          <div className="container-info-main">
            Una larga historia de 30 años, hecha de pasión y compromiso, evolución, proyectos, búsqueda y desarrollo y constante orientación al cliente. Desde 1991 Quadrifoglio fabrica mobiliario para el office living y el contract, aportando confort, funcionalidad y el placer de la belleza a las empresas y a los entornos business de todo el mundo.
          </div>

          <div className="container-year mt-10">
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <div className="info-left">
                  <h1>
                    1991
                  </h1>

                  Quadrifoglio Sistemi d’Arredo S.p.a. nace como empresa especializada <br />
                  en la fabricación de muebles para la oficina. Es la primera etapa de un <br />
                  largo camino, recurrido con determinación y dedicación, abordando cada <br />
                  día y cada proyecto como un reto.
                </div>
              </Grid>
              <Grid item xs={12} md={7}>
                <div className="image-right">
                  <img src="/images/img/quadrifoglio-group-heritage-1991.jpg" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="container-year mt-10">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <div className="info-left width-full">
                  <h1>
                    1998
                  </h1>

                  Traslado de la empresa en la nueva sede de Mansuè.
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="image-right">
                  <img src="/images/img/1998.jpg" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="container-year mt-10">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <div className="info-left width-full text-left">
                  <h1>
                    2006
                  </h1>

                  <div className="text-absolute">
                    Ampliación del establecimiento productivo // El crecimiento y el éxito de Quadrifoglio llevan la empresa a potenciar <br />
                    el establecimiento productivo para ofrecer a los clientes un servicio más eficiente y de vanguardia.
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="image-right">
                  <img src="/images/img/quadrifoglio-group-heritage-2006-.jpg" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="container-year mt-10">
            <div
              style={{
                backgroundImage: 'url(/images/img/2011.jpg)'
              }}
              className="year-background">
              <div className="info-left text-left">
                <h1>
                  2011
                </h1>

                
                Compra de Karboxx // La empresa amplia su cartera de producto con un nuevo brand exclusivo para la iluminación técnica y decorativa.
              </div>
            </div>
          </div>

          <div className="container-year mt-10">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <div className="info-left width-full text-left">
                  <h1>
                    2012
                  </h1>

                  <div className="text-absolute">
                    Creación de Offisit // A las soluciones de amueblamiento se añaden tanto <br />
                    las sillas ergonómicas para la oficina como las sillas para el contract y los <br />
                    ambientes de hospitality como sofás, butacas y sillas de diseño.
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="image-right">
                  <img src="/images/img/offisit-sedute-quadrifoglio-group.jpg" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="container-year mt-10">
            <div
              style={{
                backgroundImage: 'url(/images/img/pareti-in-vetro-xw-quadrifoglio.jpg)'
              }}
              className="year-background">
              <div className="info-left text-left">
                <h1>
                  2016
                </h1>

                Lanzamiento de la línea XW // La oferta de Quadrifoglio Group se completa <br />
                con la introducción de la gama de mamparas divisorias XW para el <br />
                replanteamiento y la reconfiguración de los espacios de oficina.
              </div>
            </div>
          </div>

          <div className="container-year mt-10">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <div className="info-left width-full text-left">
                  <h1>
                    2018
                  </h1>

                  <div className="text-absolute">
                    Apertura de showroom en el exterior // El Gruppo fortifica su presencia internacional <br />
                    gracias a la apertura de showroom de propriedad en Europa y de tiendas físicas en <br />
                    todo el mundo con sus colaboradores comerciales.
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="image-right">
                  <img src="/images/img/2018.jpg" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="container-year mt-10">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <div className="info-left width-full text-left">
                  <h1>
                    2019
                  </h1>

                  <div className="text-absolute">
                    Nueva sede corporativa // En 2019 se renova completamente la sede principal del <br />
                    Grupo, ampliando todavía más el establecimento productivo. Una actuación <br />
                    considerable realizada intentando valorizar la historia y los aspectos relacionados <br />
                    con la sostenibilidad, la linealidad y la belleza, características que distinguen el estilo <br />
                    de los productos de Quadrifoglio Group.
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="image-right">
                  <img src="/images/img/2019.png" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="container-year mt-10">
            <div
              style={{
                backgroundImage: 'url(/images/img/quadrifoglio-group-heritage-2020.jpg)'
              }}
              className="year-background">
              <div className="info-center text-left">
                <h1>
                  2020
                </h1>

                Partner global para proyecto a 360 grados // Gracias a su expansión comercial <br/>
                y a la integridad de la gama de productos, Quadrifoglio Group se convierte en <br/>
                unos de los principales players en el panorama internacional del amueblamiento <br/>
                business, un colaborador fiable y competente que puede estar al lado de clientes, <br/>
                estudios de arquitectura y diseño para la creación de proyectos completos.
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(Patrimonio);