import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
 
import './Info.scss'
// agregar cleopas
const style = {
    textDecoration: 'none',
    display: 'block',
    color: '#000',
    cursor: 'pointer',
    fontSize: '1em',
    marginBottom: '8px',
}
 
class Info extends Component {
    render() {
        return (
            <div className="container-footer-marcas">
                <Container maxWidth="lg">
                    <div className="footer__top">
                        <div className="footer__top-empresa">
                            <h2>EMPRESA</h2>
                            <br />
                            <Link
                                style={ style }
                                to="/quadrifoglio-grupo">
                                Grupo Quadrifoglio
                            </Link>
                            <Link
                                style={ style }
                                to="/quadrifoglio">
                                Nuestras Marcas
                            </Link>
                            <Link
                                style={ style }
                                to="/patrimonio">
                                Patrimonio
                            </Link>
                            <Link
                                style={ style }
                                to="/sostenibilidad-y-certificados">
                                Sostenibilidad y certificados
                            </Link>
                            <Link
                                style={ style }
                                to="/certificaciones">
                                Certificaciones
                            </Link>
                            <Link
                                style={ style }
                                to="/grupo-requiez">
                                Grupo Requiez
                            </Link>
                        </div>
                        <div className="footer__top-productos">
                            <h2>PRODUCTOS</h2>
                            <br />
                            <Link
                                style={ style }
                                to="/producto/e10" >
                                E-10
                            </Link>
                            <Link
                                style={ style }
                                to="/producto/x10" >
                                X10
                            </Link>
                            <Link
                                style={ style }
                                to="/producto/x9" >
                                X9
                            </Link>
                            <Link
                                style={ style }
                                to="/producto/x8" >
                                X8
                            </Link>
                            <Link
                                style={ style }
                                to="/producto/x5" >
                                X5
                            </Link>
                            <Link
                                style={ style }
                                to="/producto/z2" >
                                Z2
                            </Link>
                            <a
                                style={ style }
                                href="/quadrifoglio-grupo">
                                CLEOPAS
                            </a>
                            <Link
                                style={ style }
                                to="/producto/complementos" >
                                COMPLEMENTOS
                            </Link>
                            <Link
                                style={ style }
                                to="/producto/moon" >
                                MOON
                            </Link>
                            <Link
                                style={ style }
                                to="/producto/Pop-up" >
                                POP-UP
                            </Link>
                        </div>
                        <div className="footer__top-info">
                            <h2>INFO</h2>
                            <br />
                            <Link
                                style={ style }
                                to="/blog">
                                Noticias
                            </Link>
                            <Link
                                style={ style }
                                to="/contacto">
                                Contáctanos
                            </Link>
                            {/* <Link style={ style }>
                                Certificaciones
                            </Link>
                            <Link style={ style }>
                                Catálogos y Prospectos
                            </Link>
                            <Link style={ style }>
                                Noticias
                            </Link>
                            <Link style={ style }>
                                Contactos
                            </Link>
                            <Link style={ style }>
                                Exposición
                            </Link> */}
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}
 
export default Info;