import React, { Component } from 'react';

import {Link, withRouter} from "react-router-dom";


import Menu from '../../PagesComponents/Menu/Menu';
import Footer from '../../PagesComponents/Footer/Footer';
import Info from '../../PagesComponents/Info/Info';

import Container from '@material-ui/core/Container';

import MainInfo from './../../PagesComponents/Pages/Products/MainInfo/MainInfo';

import VideoYoutube from './../../PagesComponents/Pages/Products/VideoYoutube/VideoYoutube';

import './Product.scss';
import Galery from '../../PagesComponents/Pages/Products/Galery/Galery';

import Downloadable from '../../PagesComponents/Pages/Products/Downloadable/Downloadable';

import Textile from '../../PagesComponents/Pages/Products/Textile/Textile';



class Product extends Component {

  loadingPage = false;
  state = {
    product: {}
  };

  componentDidMount() {
    this.getProductInfo(this.props.match.params.urlProduct);
  }

  componentDidUpdate() {
    if (this.props.match.params.urlProduct !== this.state.product.url) {
      this.getProductInfo(this.props.match.params.urlProduct);
    }
  }

  getProductInfo(url) {
    if (!this.loadingPage) {
      this.loadingPage = true;
      fetch(`${global.config.URL.SERVER}/product/${url}`)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.setState({
            product: data.product,
            imgs: data.imgs,
            imgMain: data.imgMain[0],
            dwg: data.dwg[0],
            dataSheet: data.dataSheet[0],
            textilesCategories: data.textilesCategories,
            textiles: data.textiles,
            imgZip: data.imgZip[0]
          });
        })
        .then(() => {
          this.setState({
            loadView: false
          });
          this.loadingPage = false;
        });
    }
  }

  render() {
    return (
      <div className="product-component">

        <Menu />

        <div
          className="photoheader"
          style={{
              backgroundImage: `url(/images/${this.state.product.banner})`
          }}>
          <div className= "conteinerTitle">
              <Container maxWidth="lg">
                <h1>
                  {this.state.product.name}
                </h1>
              </Container>
          </div>
        </div>
      
        <MainInfo 
          img={(this.state.imgMain && this.state.imgMain.img) ? this.state.imgMain.img : null }
          description={this.state.product.description}
          />

        {this.state.product.video && (
          <VideoYoutube
            urlVideo={this.state.product.video}/>
        )}

        {this.state.imgs && this.state.imgs.length > 0 && (
          <Galery
            imgList={this.state.imgs}/>
        )}

        <Downloadable
          dwg={(this.state.dwg && this.state.dwg.img) ? this.state.dwg.img : null }
          dataSheet={(this.state.dataSheet && this.state.dataSheet.img) ? this.state.dataSheet.img : null }
          imgZip={(this.state.imgZip && this.state.imgZip.img) ? this.state.imgZip.img : null }/>

        <Textile
          textilesCategories={this.state.textilesCategories}
          textiles={this.state.textiles}
          timestamp={Date.now()}
        />

        <Info  />
        <Footer />
        
      </div>
    );
  }
}

export default withRouter(Product);