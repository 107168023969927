import React, { Component } from 'react';
import {
  Link, withRouter
} from "react-router-dom";
import './Patrimonio.scss';

import Menu from '../../PagesComponents/Menu/Menu';
import Footer from '../../PagesComponents/Footer/Footer';
import Info from '../../PagesComponents/Info/Info';
import Typography from '@material-ui/core/Typography';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';

import PatrimonioSection from './../../PagesComponents/Pages/Patrimonio/Patrimonio';

class Patrimonio extends Component {

  render() {
    return (
      <div className="patrimonio-component">
        <Menu />

        <div className="container-banner">
          <Banner
            name={'Patrimonio'}
            inner={
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  Inicio
                </Link>
                <Typography color="textPrimary">Patrimonio</Typography>
              </Breadcrumbs>
              }

            bannerImage = {'top-dettaglio-grace-karboxx.jpg'}
          />
        </div>

        <PatrimonioSection/>
        
        <Info  />
        <Footer />

      </div>
    );
  }
}

export default withRouter(Patrimonio);