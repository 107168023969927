import { Component } from 'react';

import Container from '@material-ui/core/Container';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import './QuadrifoglioGroup.scss';

class QuadrifoglioGroupSection extends Component {

  render() {
    return (
      <div className="quadrifoglio-group-section">
        <Container maxWidth="lg">
          <div className="description">
            Quadrifoglio Group fabrica y proyecta soluciones de amueblamiento completas para llevar el bienestar y la elegancia del diseño italiano en los ambientes de trabajo y en el contract. <br />
            Mesas de dirección y operativas, sillería, mostradores, salas de juntas, mampara, iluminación: una gama completa para realizar espacios únicos en confort y estética. <br />
            Somos inovación, diseño, gama completa, fiabilidad y respeto para el medio ambiente.
          </div>
        </Container>
        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-left">
              <h2>HACE MÁS DE 30 AÑOS HEMOS CONFIADO EN EL FUTURO</h2>
              <div className="line80w"></div>
              <br/>
              <p>
                Nuestro corazón late desde 1991 y hemos crecido año trás año hasta convertinos en una empresa de referencia en el sector del amueblamiento profesional. Desde fabricante de muebles para la oficina hasta llegar a ser un partner global y completo. Hoy somos reconocidos como un Grupo sólido que puede llevar al cabo proyectos de espacios business y hospitality a 360 grados, innovadores y de diseño, garantizando siempre una cadena de suministro Made in Italy.
              </p>
            </div>
            <div className="image-right">
              <iframe className="vimeo_iframe" src="https://player.vimeo.com/video/514245954?loop=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;color=d11d14" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" title="video"></iframe>
            </div>
          </div>
        </Container>

        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-right">
              <h2>LA EVOLUCIÓN DE LOS AMBIENTES PROFESIONALES</h2>
              <div className="line80w"></div>
              <br/>
              <p>
                Las transformaciones del trabajo y del business así como el rápido cambio tecnológico han modificado procedimientos, hábitos y necesidades del mundo profesional, dando vida a nuevas soluciones.
                <br />
                Quadrifoglio Group está cerca de sus clientes y colaboradores, los escucha y los apoya para crear soluciones idóneas a sus necesidades. Ponemos toda nuestra experiencia de 30 años y nuestros know-how a disposición del cliente para el desarrollo y el diseño de espacios de trabajo y hospitality.
              </p>
            </div>
            <div className="image-left">
              <Carousel
                autoPlay={true}
                interval={2000}
                infiniteLoop={true}
                emulateTouch={true}>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776570-quadrifoglio-group-professional-environments-X-Change-1.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776578-quadrifoglio-group-professional-environments-Up&Up+OXYGEN-3.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/quadrifoglio-group-professional-environments-Arco_2.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776442-quadrifoglio-group-professional-environments-Code-4.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776585-quadrifoglio-group-professional-environments-Arco-5.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776427-quadrifoglio-group-professional-environments-City-lounge-6.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776436-quadrifoglio-group-professional-environments-City-lounge-7.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776479-quadrifoglio-group-professional-environments-Avana-8.jpeg)'
                    }}>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </Container>
        
        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-left">
              <h2>EL BIENESTAR LABORAL</h2>
              <div className="line80w"></div>
              <br/>
              <p>
                Posturas e instrumentos de trabajo pueden mejorar nuestro rendimiento, protegiendo nuestra salud y nuestro estado de ánimo. Quadrifoglio Group trabaja en contacto continuo con Diseñadores y Proyectistas que poseen esta sensibilidad, innovando y buscando soluciones que unan estética y diseño, sostenibilidad empresarial y respeto del medio ambiente, asistencia completa a la calidad de nuestros productos.
              </p>
            </div>
            <div className="image-right">
              <div
                className="img-slider"
                style={{
                  'backgroundImage': 'url(/images/banners/corporate_quadrifoglio_benessere_lavorativo.jpeg)'
                }}>
              </div>
            </div>
          </div>
        </Container>

        <Container maxWidth="xl">
          <div className="container-section-info">
            <div className="info-right">
              <h2>SOSTENIBILIDAD Y RESPETO POR EL MEDIO AMBIENTE</h2>
              <div className="line80w"></div>
              <br/>
              <p>
                Nos interesa mucho el medio ambiente en el que vivimos, por eso Quadrifoglio Group ha decidido actuar con una política responsable para preservar la naturaleza y favorecer un desarrollo sostenible. Nuestro compromiso está certificado en la seguridad del producto y del medio ambiente (ISO) en el uso responsable de los recursos FSC®. Conscientes de la necesidad de un mundo mejor, la nueva sede corporativa estrenada en 2019 ha sido proyectada con especial cuidado en relación a la conservación del medio ambiente y a los principios de sostenibilidad. Queremos contribuir a aumentar la calidad del trabajo y, como consecuencia, mejorar la calidad de la vida.
              </p>
            </div>
            <div className="image-left">
              <Carousel
                autoPlay={true}
                interval={2000}
                infiniteLoop={true}
                emulateTouch={true}>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776115-quadrifoglio-group-sustainability-1.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776127-quadrifoglio-group-sustainability-2.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776142-quadrifoglio-group-sustainability-3.jpeg)'
                    }}>
                  </div>
                </div>
                <div>
                  <div
                    className="img-slider"
                    style={{
                      'backgroundImage': 'url(/images/banners/1612776141-quadrifoglio-group-sustainability-4.jpeg)'
                    }}>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default QuadrifoglioGroupSection;