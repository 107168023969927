import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';

import cert1 from '../../../certificados/cert1.jpg';
import cert2 from '../../../certificados/cert2.jpg';
import cert3 from '../../../certificados/cert3.jpg';
import cert4 from '../../../certificados/cert4.jpg';
import cert5 from '../../../certificados/cert5.jpg';
import cert6 from '../../../certificados/cert6.jpg';
import cert7 from '../../../certificados/cert7.jpg';
import cert8 from '../../../certificados/cert8.jpg';

import './Certificate.scss';

const certificados = [
    {
        id: 1,
        title: 'Company Certifications',
        image: cert1,
        url: '/files/12019-Company-Certifications.pdf',
    },
    {
        id: 2,
        title: 'Company to Watch',
        image: cert2,
        url: '/files/22016_CompanyToWatch_Cerved_Marketing_Solutions_IT.pdf',
    },
    {
        id: 3,
        title: 'ISO 9001:2015',
        image: cert3,
        url: '/files/3ISO-9001-ENG.pdf',
    },
    {
        id: 4,
        title: 'ISO 45001:2018',
        image: cert4,
        url: '/files/4ISO-459001-ENG.pdf',
    },
    {
        id: 5,
        title: 'Certificate of Registration',
        image: cert5,
        url: '/files/5FSC-Certificate-2020-09-11.pdf',
    },
    {
        id: 6,
        title: 'Pannello Ecologico',
        image: cert6,
        url: '/files/6Certificato_CPE_2021_Quadrifoglio_Spa.pdf',
    },
    {
        id: 7,
        title: 'ISO 14001:2015',
        image: cert7,
        url: '/files/7ISO-14001.PDF',
    },
    {
        id: 8,
        title: 'Politiche Quadrifoglio',
        image: cert8,
        url: '/files/8POLITICA2019.pdf',
    },
]

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    maxHeight: 500,
    backgroundColor: '#fff',
  },
  media: {
    height: 350,
    width: 300,
  }
});

const Certificate = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="xl">
            <div className="certificate-container">
                {
                    certificados.map(({ id, title, image, url }) => {
                        return <div className="certificate-card" key={ id }>
                            <a size="small" color="primary" href={ url } target="_blank" without rel="noreferrer">
                                <Card className={classes.root}>
                                    <CardActionArea>
                                        <CardMedia
                                        className={classes.media}
                                        image={ image }
                                        title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" >
                                                { title }
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        Versión PDF
                                    </CardActions>
                                </Card>
                            </a>
                        </div>
                    })
                }
            </div>
        </Container>
    )
}

export default Certificate