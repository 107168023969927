import React, { Component } from 'react';

import './CategoryCard.scss';

class CategoryCard extends Component {
    render() {
        const { url, imgUrl, name } = this.props.product;

        if (url) {
          return (
            <a href={`/producto/${url}`}>
              <div
                className="category-card"
                style={{
                  backgroundImage: `url("/images/${imgUrl}")`
                }}
                >
                <div className="container-title">
                  <h3 className="title">
                    {name}
                  </h3>
                </div>
              </div>
            </a>
          )
        }

        return false;
    }
}

export default CategoryCard