import { Component } from 'react';

import Container from '@material-ui/core/Container';
import CategoryCard from '../../../GeneralComponents/CategoryCard/CategoryCard';

import './ProductsList.scss';

class ProductsList extends Component {

  state = {
    loadView: true,
    idCategory: null,
    productsList: [],
    textiles: [],
    imgList: [],
    imgMinList: []
  };

  componentDidMount() {
    this.getProductsList();
  }

  getProductsList() {
    fetch(`${global.config.URL.SERVER}/categories/products`)
      .then(response => response.json())
      .then(data => this.setState({
        productsList: data.productList
      }))
      .then(() => {
        this.setState({
          loadView: false
        });
      });
  }

  render() {
    return (

      <Container maxWidth="xl">
        <div className="products-list-component">
          {this.state.productsList.map((value, index) => {
            return <CategoryCard
              key={index}
              product={{
                url: value.url,
                name: value.name,
                imgUrl: value.imgUrl,
                imgName: value.imgName,
              }}/>
          })}
        </div>
      </Container>
    );
  }
}

export default ProductsList;