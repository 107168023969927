import { Component } from 'react';
import renderHTML from 'react-render-html';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import {
  Link
} from "react-router-dom";

import './SelectNote.scss';

class SelectNote extends Component {
  render() {
    return (
      <div className="select-note-horizontal">
        <Link to={`/blog/${this.props.url}`}>
          <Grid container>
            <Grid item xs={12}>
              <div
                className="image-note"
                style={{
                backgroundImage: `url(${this.props.urlImg})`
                }}></div>
            </Grid>
            <Grid item xs={12}>
              <CardContent className="container-text">
                <Typography gutterBottom variant="h5" component="h2">
                  { renderHTML(this.props.title) }
                </Typography>
                <div className="line80"></div>
                <Typography variant="body2" className="summarize" component="p">
                  <div>
                    { renderHTML(this.props.summarize) }
                  </div>
                </Typography>
                <div className="read-all">
                  Leer nota completa
                </div>
              </CardContent>
            </Grid>
          </Grid>
        </Link>
      </div>
    );
  }
}


export default SelectNote;