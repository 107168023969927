import { Component } from 'react';

import Container from '@material-ui/core/Container';

import './Filters.scss';

class Filters extends Component {

  render() {
    return (
      <div className="filters-component">
        <div className="container-products-type">
            <div className="title">
              <Container maxWidth="xl">
                Categoría de producto
              </Container>
            </div>
            <div className="conteiner-cat">
              <span className="container-img">
                <img src="/images/categories-ico/cat-1.png" alt="" srcSet="" />
                <div className="category-name">
                  Escritorio operativo
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-11.png" alt="" srcSet="" />
                <div className="category-name">
                  Escritorio ejecutivo
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-2.png" alt="" srcSet="" />
                <div className="category-name">
                  Mesas de reunión
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-12.png" alt="" srcSet="" />
                <div className="category-name">
                  Mesas de centro
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-4.png" alt="" srcSet="" />
                <div className="category-name">
                  Sillas de oficina
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-10.png" alt="" srcSet="" />
                <div className="category-name">
                  Sillas de colectividades y Sofas
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-5.png" alt="" srcSet="" />
                <div className="category-name">
                  Iluminación decorativa
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-6.png" alt="" srcSet="" />
                <div className="category-name">
                  Iluminación de oficina
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-7.png" alt="" srcSet="" />
                <div className="category-name">
                  Recepción
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-8.png" alt="" srcSet="" />
                <div className="category-name">
                  Mamparas dividoras y Cabinas acústicas
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-9.png" alt="" srcSet="" />
                <div className="category-name">
                  Aislamiento acústico
                </div>
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-13.png" alt="" srcSet="" />
                <div className="category-name">
                  Biblioteca y Boiserie
                </div> 
              </span>
              <span className="container-img">
                <img src="/images/categories-ico/cat-14.png" alt="" srcSet="" />
                <div className="category-name">
                  Armarios y Lockers
                </div>
              </span>
            </div>
        </div>
      </div>
    );
  }
}

export default Filters;