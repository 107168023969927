import React, { Component } from 'react'

import Certificate from '../../PagesComponents/Certificate/Certificate';
import Footer from '../../PagesComponents/Footer/Footer';
import Info from '../../PagesComponents/Info/Info';
import Menu from '../../PagesComponents/Menu/Menu';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {
    Link
  } from "react-router-dom";

import './Certifications.scss';

export default class Certifications extends Component {
    render() {
        return (
            <div className="product-component">
                <Menu />

                <Banner
                    bannerImage={'storia.jpg'}
                    name={'CERTIFICACIONES'}
                    inner={
                        <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" to="/">
                            Inicio
                        </Link>
                        <Link color="inherit" to="/certificaciones">
                            Certificaciones
                        </Link>
                        </Breadcrumbs>
                        }  
                    />
                <Certificate />
                <Info />
                <Footer />
            </div>
        )
    }
}
