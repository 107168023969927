import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import {
  Link
} from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Container from '@material-ui/core/Container';
import Menu from '../../PagesComponents/Menu/Menu';
import Footer from './../../PagesComponents/Footer/Footer';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Instagram from './../../PagesComponents/GeneralComponents/Instagram/Instagram';
import Carousel from './../../PagesComponents/Carousel/Carousel';

import './About.scss';

const styles = {
  image: {
    width: '50%',
    margin: '25px auto',
    display: 'grid',
  },
  head2: {
    margin: '10px auto',
  },
}


class About extends Component {

  render(){
    return (
      <div className="about-component">
        {/* <div className={`loadView ${(this.state.loadView) ? 'show' : ''}`}></div> */}
        <Menu />
        <Banner inner={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Inicio
            </Link>
            <Typography color="inherit">
              Nosotros
            </Typography>
          </Breadcrumbs>
          }

          bannerImage = {'nosotros.jpg'}
        />

        <Container maxWidth="md" className="mb-10">
          <img style={styles.image} src="/images/img/grupo-requiez-logo.png" alt="Logo Requiez"/>

          <img className="img-note mb-5" src="/images/img/about2-new.png" alt="Nosotros"/>
            
          <p>
            Quadrifoglio forma parte de Grupo Requiez, empresa orgullosamente mexicana con más de 35 años de trayectoria, que ha dejado huella en la industria del mobiliario para oficinas, contract y colectividad. Desde las empresas más prominentes hasta los hogares más selectos, nuestra presencia indiscutible en las principales ciudades del país se debe a la filosofía que nos ha guiado durante todo nuestro camino: sabiduría creativa, pensamiento positivo, y tenacidad inquebrantable. Estos valores están presentes en todas las relaciones establecidas con nuestros clientes, empleados y socios comerciales.
          </p>

        </Container>
        <Carousel />
        <Footer/>
      </div>
    );
  }
}

export default About;
