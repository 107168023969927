import { Breadcrumbs } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import Menu from "../Menu/Menu";
import { Carousel } from "react-bootstrap";



import "./HeaderHome.scss";



const styles = {
  textDecoration: "none",
  color: "white",
  fontWeight: "400",
  fontSize: "1em",
};


const Header = () => {
  return (
    <div className="header">
      <Menu />
      <Hidden smDown>
        <div className="header__menu">
          <span className="header__list">
            <Breadcrumbs
              style={{
                fontSize: "20px",
                fontWeight: "200",
                color: "#FFF",
              }}
              separator="|"
              aria-label="breadcrumb"
            >
              <div className="container-menu">
                <Link style={styles} to="/">
                  Inicio
                </Link>
              </div>
              <div className="container-menu">
                <Link style={styles} to="/quadrifoglio-grupo">
                  Empresa
                </Link>
                <div className="submenu">
                  <div className="submenu-link">
                    <Link style={styles} to="/quadrifoglio-grupo">
                      Grupo Quadrifoglio
                    </Link>
                  </div>
                  <div className="submenu-link">
                    <Link style={styles} to="/quadrifoglio">
                      Nuestra marca
                    </Link>
                  </div>
                  <div className="submenu-link">
                    <Link style={styles} to="/patrimonio">
                      Patrimonio
                    </Link>
                  </div>
                  <div className="submenu-link">
                    <Link style={styles} to="/sostenibilidad-y-certificados">
                      Sostenibilidad y certificados
                    </Link>
                  </div>
                  <div className="submenu-link">
                    <Link style={styles} to="/certificaciones">
                      Certificaciones
                    </Link>
                  </div>
                  <div className="submenu-link">
                    <Link style={styles} to="/grupo-requiez">
                      Grupo Reqüiez
                    </Link>
                  </div>
                </div>
              </div>
              <div className="container-menu">
                <Link style={styles} to="/">
                  Productos
                </Link>
                <div className="submenu">
                  <Link style={styles} to="/producto/e10">
                    <div className="submenu-link">e-10</div>
                  </Link>
                  <Link style={styles} to="/producto/x10">
                    <div className="submenu-link">X10</div>
                  </Link>
                  <Link style={styles} to="/producto/x9">
                    <div className="submenu-link">X9</div>
                  </Link>
                  <Link style={styles} to="/producto/x8">
                    <div className="submenu-link">X8</div>
                  </Link>
                  <Link style={styles} to="/producto/x5">
                    <div className="submenu-link">X5</div>
                  </Link>
                  <Link style={styles} to="/producto/z2">
                    <div className="submenu-link">Z2</div>
                  </Link>
                  <Link style={styles} to="/producto/cleopas">
                    <div className="submenu-link">Cleopas</div>
                  </Link>
                  <Link style={styles} to="/producto/Pop-up">
                    <div className="submenu-link">Pop-Up</div>
                  </Link>
                  <Link style={styles} to="/producto/complementos">
                    <div className="submenu-link">Complementos</div>
                  </Link>
                  <Link style={styles} to="/producto/moon">
                    <div className="submenu-link">Moon</div>
                  </Link>
                </div>
              </div>
              <div className="container-menu">
                <Link style={styles} to="/blog">
                  Noticias
                </Link>
              </div>
              <div className="container-menu">
                <Link style={styles} to="/contacto">
                  Contáctanos
                </Link>
              </div>
            </Breadcrumbs>
          </span>
        </div>
      </Hidden>

      <section className="photogallery_homepage" id="home">
  <div className="photogallery_video">
    <img src="/images/moon1.webp" alt="Quadrifoglio" style={{ maxWidth: "1500px" }}/>
  </div>
</section>

     {/* <Carousel 
          nextLabel={''}
          prevLabel={''}
        >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/moon1.webp"
              alt="Banner home"
            />
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/moon2.webp"
              alt="Banner home"
            />
          </Carousel.Item>
          
        </Carousel>

      {/*<Carousel
        nextIcon={''}
        prevIcon={''}
        nextLabel={''}
        prevLabel={''}
        style={{ width: '100%' }}
      >
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={image}
              alt={names[index]}
            />
          </Carousel.Item>
        ))}
      </Carousel>

      

         
 {/*<video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        id="myVideo">
                        <source src="/video/Quadrifoglio.mp4" type="video/mp4" />
                      
                    </video>
                    <div className="logopayoff">
                        <img src="/images/logoQuadrifoglio.png" alt="logo"/>
                        <h1>OFFICE FURNITURE</h1>
            </div>*/}
    </div>
  );
};

export default Header;

{
  <div
                        className="logopayoff">
                        <img src="/images/logoQuadrifoglio.png" alt="logo"/>
                        <h1>OFFICE</h1>
</div>
}
