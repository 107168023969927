import { Component } from 'react';
import StackGrid from "react-stack-grid";
import Viewer from "react-viewer/dist/index.js";
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import './Galery.scss';

class Galery extends Component{

    state = {
        visible: false,
        idxOpen: 0
    }

    setVisible(status, idx) {
        this.setState({
          visible: status,
          idxOpen: idx
        })
    }

    render(){
        return(
            <Container maxWidth="lg">
                <div className = "contaner-grid">
                    <Hidden mdDown>
                        <StackGrid
                        monitorImagesLoaded={true}
                        columnWidth={'33%'}
                        >
                            {this.props.imgList && this.props.imgList.map((img, idx) =>
                                <div
                                    key={idx}
                                    className="img-StackGrid"
                                    onClick={() => {
                                        this.setVisible(true, idx);
                                    }}>
                                    <img src={`/images/${img.img}`} alt="" srcSet="" />
                                </div>
                            )}
                        </StackGrid>
                    </Hidden>
                    <Hidden mdUp>
                        <StackGrid
                        monitorImagesLoaded={true}
                        columnWidth={'50%'}
                        >
                            {this.props.imgList && this.props.imgList.map((img, idx) =>
                                <div
                                    key={idx}
                                    className="img-StackGrid"
                                    onClick={() => {
                                        this.setVisible(true, idx);
                                    }}>
                                    <img src={`/images/${img.img}`} alt="" srcSet="" />
                                </div>
                            )}
                        </StackGrid>
                    </Hidden>
                </div>
                <div>
                    {this.props.imgList && (
                        <Viewer
                            visible={this.state.visible}
                            activeIndex={this.state.idxOpen}
                            drag={false}
                            zoomable={false}
                            rotatable={false}
                            scalable={false}
                            onClose={() => {
                                this.setVisible(false);
                            }}
                            images={this.props.imgList.map(img => ({
                                src: `/images/${img.img}`
                            }) )}
                        />
                    )}
                </div>
            </Container>
        )
    }
}
export default Galery;