import { Component } from 'react';

import InstagramIcon from '@material-ui/icons/Instagram';

import './Instagram.scss';

class Instagram extends Component {

  state = {
    instagramInfo: {},
    imageList: []
  }

  componentDidMount() {
    this.getInstagramInfo();
  }

  getInstagramInfo() {
    fetch(`${global.config.URL.SERVER}/instagram`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          this.setState({
            instagramInfo: data.data,
            imageList: data.media
          })
        }
      });
  }

  render() {
    return (
      <div className="select-instagram">
        <div className="container-instagram">
          <div className="flex-center">
            <div className="section-title">
              <a target="_blank" href="https://www.instagram.com/requiez.sillas/" rel="noreferrer">
                <img src="/img/insta.png" alt="Instagram" />
                <h2>Conoce Nuestro Instagram</h2>
              </a>
              <a target="_blank" href="https://www.instagram.com/requiez.sillas/" rel="noreferrer" className="border-btn">Descubre Más</a>
            </div>
          </div>
          {this.state.imageList.length > 0 && [...Array(3)].map((value, index) => {
            if (
              this.state.imageList[index]) {
              return (
                <a key={index} href={`${this.state.imageList[index].shortcode}`} target="_blank" rel="noopener noreferrer" className="link-instagram-image">
                  <div className="container-image">
                    <img src={this.state.imageList[index].src} alt="Imagen de instagram"/>
                    <div className="icon">
                      <InstagramIcon/>
                    </div>
                  </div>
                </a>
              )
            }
            return true;
          })}
        </div>
      </div>
    );
  }
}


export default Instagram;