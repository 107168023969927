import React, { Component } from 'react';

import Description from '../../PagesComponents/Description/Description';
import Footer from '../../PagesComponents/Footer/Footer';
import HeaderHome from '../../PagesComponents/HeaderHome/HeaderHome';
import Info from '../../PagesComponents/Info/Info';
import Container from '@material-ui/core/Container';
import SelectNote from './../../PagesComponents/GeneralComponents/SelectNote/SelectNote';
import ProductsList from '../../PagesComponents/Pages/Products/ProductsList/ProductsList';
import VirtualExp from '../../PagesComponents/VirtualExp/VirtualExp';

import './Home.scss';

class Home extends Component {

  state = {
    posts: [],
    page: 0,
    loading: false,
    loadMore: true
  };

  componentDidMount() {
    this.getAllPosts();
  }

  getAllPosts() {
    this.setState({
      loading: true
    });
    fetch(`${global.config.URL.SERVER}/postsByPage/${this.state.page + 1}`, { 
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        const postList = data.map((post) => ({
            title: post.title ? post.title : '',
            createdBy: post.created_by ? post.created_by : '',
            createdSite: post.created_site ? post.created_site : '',
            summary: post.summary ? post.summary : '',
            url: post.url ? post.url : '',
            socialNetworkImage: post.socialNetworkImage ? post.socialNetworkImage : '',
          })
        );
        this.setState({
          page: this.state.page + 1,
          posts: [
            ...this.state.posts,
            ...postList
          ],
          loading: false,
          loadMore: (data.length > 0) ? true : false
        });
      }
    })
    .catch( err => {
      console.log(err);
    });
  }


  render() {
    return (
      <div className="home-component">
        <HeaderHome />
        <Description />
        <ProductsList/>
        {/* <VirtualExp />
        <Container maxWidth="lg" className="my-12">
          {this.state.posts && this.state.posts[0] && (
            <SelectNote
              title={this.state.posts[0].title}
              summarize={this.state.posts[0].summary}
              urlImg={this.state.posts[0].socialNetworkImage}
              url={this.state.posts[0].url}/>
          )}
        </Container> */}

        <Info  />
        <Footer />
      </div>
    );
  }
}

export default Home;