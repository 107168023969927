import { Component } from 'react';

import Menu from '../../PagesComponents/Menu/Menu';
import Footer from '../../PagesComponents/Footer/Footer';
import Info from '../../PagesComponents/Info/Info';

import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import QuadrifoglioSection from './../../PagesComponents/Pages/Quadrifoglio/Quadrifoglio';
import {
  Link, withRouter
} from "react-router-dom";

import './Quadrifoglio.scss';

class Quadrifoglio extends Component {

  render() {
    return (
      <div className="quadrifoglio">

        <Menu />

        <Banner
          bannerImage={'top-dettaglio-seduta-dune--quadrifoglio.jpeg'}
          name={'NUESTRAS MARCAS'}
          inner={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/">
                Inicio
              </Link>
              <Link color="inherit" to="/quadrifoglio">
                Empresa
              </Link>
            </Breadcrumbs>
            }  
          />
        <QuadrifoglioSection/>

        <Info  />
        <Footer />
      </div>
    );
  }
}

export default withRouter(Quadrifoglio);