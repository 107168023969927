import { Component } from 'react';
import Container from '@material-ui/core/Container';
import './Downloadable.scss';

class Downloadable extends Component{
    render(){
        return(
            <div className='downloadable'>
                <Container maxWidth="lg">
                    <div className="downloadable-buttons">
                        <div>
                            {this.props.dwg && (
                                <a href={`/images/${this.props.dwg}`} target="_blank">
                                    Descarga 3D
                                </a>
                            )}
                        </div>
                        <div>
                            {this.props.dataSheet && (
                                <a href={`/images/${this.props.dataSheet}`} target="_blank">
                                    Ficha técnica
                                </a>
                            )}
                        </div>
                        <div>
                            {this.props.imgZip && (
                                <a href={`/images/${this.props.imgZip}`} target="_blank">
                                    Imágenes
                                </a>
                            )}
                        </div>
                    </div>
            </Container>
            </div>
        )
    }
}

export default Downloadable;