import React from 'react'
import { Container } from '@material-ui/core';

import './Description.scss';

const Description = () => {
    return (
        <div className="description-section">
            <Container 
                style={{
                    display: 'grid',
                    placeItems: 'center',
                    width: 'calc(100% - 16px)',
                    margin: '0 auto',
                }}
            >
                <div className="description">
                    <div className="description__img">
                        <img src="/images/logo-30.png" alt="Logo 30 aniversario" />
                    </div>
                    <div className="description__title">
                        <h2>TODOS LOS DÍAS CONTIGO</h2>
                    </div>
                    <div className="description__content">
                        <p>Desde hace 30 años, Quadrifoglio Group fabrica y proyecta mobiliario para el office living y el contract, realizando espacios completos para mejorar las experiencias de las personas en los entornos profesionales y en el sector de la hospitalidad.</p>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Description