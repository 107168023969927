import React, { Fragment, Component } from 'react'
import Menu from '../../PagesComponents/Menu/Menu';
import './Iframe.css'

class Iframe extends Component {
    render() {
        return (
            <Fragment>
                <Menu />
                <iframe className="iframe" src="https://www.quadrifoglio.com/showroom-experience/index.htm" title="show room"/>
            </Fragment>
        )
    }
}

export default Iframe