import React, { Component } from 'react';
import { pushRotate as Menus } from 'react-burger-menu';
import { Link } from 'react-router-dom';

import './Menu.scss';

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '30px',
    height: '25px',
    left: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    background: '#000'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenu: {
    background: 'white',
    padding: '0',
    fontSize: '1em',
    overflow: 'none'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#fff',
    padding: '0.8em'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: '0',
    left: '0',
  }
}

class Menu extends Component {

    render () {
        return (
        <Menus styles={ styles }>
          <div className="container-logo-30">
            <img src="/images/logo-30-blue.png" alt="logo-30-años" />
          </div>
          <div className="container-menu">
            <Link 
              to="/" >
              <span className="title-section">
                Inicio
              </span>
            </Link>
          </div>
          <div className="container-menu">
            <span className="title-section">
              Empresa
            </span>
            <div className="submenu">
              <div className="submenu-link">
                <Link 
                  to="/quadrifoglio-grupo" >
                  Grupo Quadrifoglio
                </Link>
              </div>
              <div className="submenu-link">
                <Link 
                  to="/quadrifoglio" >
                  Nuestra marca
                </Link>
              </div>
              <div className="submenu-link">
                <Link 
                  to="/patrimonio" >
                  Patrimonio
                </Link>
              </div>
              <div className="submenu-link">
                <Link 
                  to="/sostenibilidad-y-certificados" >
                  Sostenibilidad y certificados
                </Link>
              </div>
              <div className="submenu-link">
                <Link 
                  to="/certificaciones" >
                  Certificaciones
                </Link>
              </div>
              <div className="submenu-link">
                <Link 
                  to="/grupo-requiez" >
                  Grupo Requiez
                </Link>
              </div>
            </div>
          </div>
          <div className="container-menu">
            <span className="title-section">
              Productos
            </span>
            <div className="submenu">
              <Link 
                to="/producto/e10" >
                <div className="submenu-link">
                  e-10
                </div>
              </Link>
              <Link 
                to="/producto/x10" >
                <div className="submenu-link">
                  X10
                </div>
              </Link>
              <Link 
                to="/producto/x9" >
                <div className="submenu-link">
                  X9
                </div>
              </Link>
              <Link 
                to="/producto/x8" >
                <div className="submenu-link">
                  X8
                </div>
              </Link>
              <Link 
                to="/producto/x5" >
                <div className="submenu-link">
                  X5
                </div>
              </Link>
              <Link 
                to="/producto/z2" >
                <div className="submenu-link">
                  Z2
                </div>
              </Link>
              <Link 
                to="/producto/cleopas" >
                <div className="submenu-link">
                  Cleopas
                </div>
              </Link>
              <Link 
                to="/producto/Pop-up" >
                <div className="submenu-link">
                  Pop-Up
                </div>
              </Link>
              <Link 
                to="/producto/complementos" >
                <div className="submenu-link">
                  Complementos
                </div>
              </Link>
              <Link 
                to="/producto/moon" >
                <div className="submenu-link">
                  Moon
                </div>
              </Link>
            </div>
          </div>
          <div className="container-menu">
            <Link
                style={ styles }
                to="/blog">
                <span className="title-section">
                  Noticias
                </span>
            </Link>
          </div>
          <div className="container-menu">
            <Link
              style={ styles }
              to="/contacto">
              <span className="title-section">
                Contáctanos
              </span>
            </Link>
          </div>
        </Menus>
        );
    }
}

export default Menu