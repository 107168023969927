import { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import './Textile.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class Textile extends Component {

    loadingData = false;
    state = {
      id: null,
      valueTab: 0,
      textiles: []
    };
    
    handleChange(newValue) {
      this.setState({
        valueTab: newValue
      });
    };

    componentDidMount() {
      this.orderTextiles();
    }

    componentWillUpdate() {
      if (this.props.timestamp !== this.state.id) {
        this.orderTextiles();
      }
    }

    orderTextiles() {
      if (!this.loadingData && this.props.textilesCategories) {
        this.loadingData = true;
        const textiles = this.props.textilesCategories.map(textileCat => {
          return {
            textileCat,
            textiles: this.props.textiles.filter(textil => textil.idTextileCategories === textileCat.id)
          };
        });
        this.setState({
          textiles,
          id: this.props.timestamp
        });
        this.loadingData = false;
      }
    }

    render() {
      const self = this;
      return(
          <div className="container-textiles">
            <Container maxWidth="lg">
              <div className="title-textiles">
                <h2>
                  Acabados
                </h2>
              </div>
              {this.state.textiles.length > 0 && (
                <div>
                  <div className='box-textiles'>
                  <AppBar className="bar-textiles" position="static">
                    <Tabs
                      value={this.state.valueTab}
                      onChange={(event, newValue) => this.handleChange(newValue)}
                      aria-label="simple tabs example">
                        {this.state.textiles.map(function(textilCat, i){
                          return <Tab className="tab-btn" label={textilCat.textileCat.name} id={i} key={i} />;
                        })}
                    </Tabs>
                  </AppBar>
                  <div className="bar-gray"></div>
                </div>
                {this.state.textiles.map(function(textilCat, i){
                  return (
                    <TabPanel className="tab-panel-info" value={self.state.valueTab} index={i} key={i}>
                      <div className="image-textiles">
                        {textilCat.textiles.map(function (tela, i){
                          return (
                            <div
                              key={i}
                              className="conatiner-image-textil"
                              style={{
                              backgroundImage: `url(/images/${tela.img})`}}>
                              <div className="thumb-finiture-title">{tela.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </TabPanel>
                  );
                })}  
                </div>
              )}
            </Container>
          </div>
      )
    }
}

export default Textile;