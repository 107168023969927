import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import './VirtualExp.scss'

export default function VirtualExp() {
    return (
        <div className="experiencia">
            <Container maxWidth="lg">
                <div className="experiencia__container">
                    <h2 className="experiencia__h2">EXPERIENCIA VIRTUAL</h2>
                    <h3 className="experiencia__h3">Visita el showroom de la nueva sede.</h3>
                    <span className="experiencia__p top">Un recorrido virtual, inmersivo e interactivo que hará que descubras las colecciones Quadrifoglio y Karboxx.
                    </span>
                    <span className="experiencia__p bot">Vive desde cerca todos los productos, haz que te inspiren nuestros modelos de oficinas y espacios colectivos a través de un itinerario dentro de nuestro showroom que acabamos de renovar completamente.
                    </span>
                </div>
                <div className="experiencia__image">
                    <img 
                        className="experiencia__image-img" 
                        src="/images/homepage-virtualtour.png" 
                        alt="Virtual showroom" 
                    />
                    <div className="experiencia__image-button">
                        <Link
                            to="/experiencia-virtual"
                        >
                            Visita Nuestra Experiencia Virtual
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    )
}