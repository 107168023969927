import React, { Component } from 'react';

import {
  withRouter
} from "react-router-dom";

import Container from '@material-ui/core/Container';
import SelectNote from './../../GeneralComponents/SelectNoteHorizontal/SelectNote';

import './OtherNotes.scss';

class OtherNotes extends Component {

  state = {
    posts: [],
    page: 0,
    loading: false,
    loadMore: true
  };

  componentDidMount() {
    this.getAllPosts();
  }

  nextPosts() {
    this.getAllPosts();
  }


  getAllPosts() {
    this.setState({
      loading: true
    });
    fetch(`${global.config.URL.SERVER}/postsByPage/${this.state.page + 1}`, { 
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        const postList = data.map((post) => ({
            title: post.title ? post.title : '',
            createdBy: post.created_by ? post.created_by : '',
            createdSite: post.created_site ? post.created_site : '',
            summary: post.summary ? post.summary : '',
            url: post.url ? post.url : '',
            socialNetworkImage: post.socialNetworkImage ? post.socialNetworkImage : '',
          })
        );
        this.setState({
          page: this.state.page + 1,
          posts: [
            ...this.state.posts,
            ...postList
          ],
          loading: false,
          loadMore: (data.length > 0) ? true : false
        });
      }
    })
    .catch( err => {
      console.log(err);
    });
  }

  render() {
    return (
      <div className="blog-OtherNotes">

        <div className="container-news-list">
          <Container maxWidth="lg" className="mb-10">
              {this.state.posts.map((value, index) => {
                return <div
                  className="mb-5"
                  key={index}>
                  <SelectNote
                    title={value.title}
                    summarize={value.summary}
                    urlImg={value.socialNetworkImage}
                    url={value.url}/>
                </div>;
              })}
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(OtherNotes);