import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import './Carousel.scss'

class Carousel extends Component {
  render() {
    return (
      <Container maxWidth="md">
        <h2 className="text-center mb-5">NUESTRAS MARCAS</h2>
        <section className="carousell">
          <div className="carousell__container">
            <div className="carousell-item">
              <a href="https://requiez.com" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/images/about/requiez.png"
                  alt="Requiez"
                />
              </a>
            </div>

            <div className="carousell-item">
              <a href="https://infinitidesignmexico.com/es" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/images/about/infiniti.png"
                  alt="Infiniti"
                />
              </a>
            </div>

            <div className="carousell-item">
              <a href="https://labenze.com" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/images/about/labenze.png"
                  alt="Labenze"
                />
              </a>
            </div>

            <div className="carousell-item">
              <a href="https://okamuramexico.com" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/images/about/okamura.png"
                  alt="Okamura"
                />
              </a>
            </div>

            <div className="carousell-item">
              <a href="https://quadrifoglio.com.mx" target="_blank">
                <img
                  className="carousell-item__img"
                  src="/images/about/quadrifoglio.png"
                  alt="Quadrifoglio"
                />
              </a>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}

export default Carousel;
