import React, { Component } from 'react';

import {Link, withRouter} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';

import Container from '@material-ui/core/Container';

import Menu from '../../PagesComponents/Menu/Menu';
import Footer from '../../PagesComponents/Footer/Footer';
import Info from '../../PagesComponents/Info/Info';

import OtherNotes from './../../PagesComponents/Notes/OtherNotes/OtherNotes';
import Dynamic from './../../PagesComponents/Notes/Dynamic/Dynamic';

import './Note.scss';

class Note extends Component {

  state = {
    idBlog: null 
  };


  componentDidMount() {
    console.log(this.props.match.params)
    this.setState({
      idBlog: this.props.match.params.idBlog
    });
    this.getNote(this.props.match.params.idBlog);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getNote(this.props.match.params.idBlog);
    }
  }

  getNote(idBlog) {
    fetch(`${global.config.URL.SERVER}/postsByUrl/${idBlog}`, { 
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data) {
        this.setState({
          post: data
        });
      }
    })
    .catch( err => {
      console.log(err);
    });
  }

  render() {
    return (
      <div className="blog-note-component">
        {/* <div className={`loadView ${(this.state.loadView) ? 'show' : ''}`}></div> */}
        <Menu />

        {(( this.state.post && this.state.post.title) && (
          <div className="container-info-note">
            <Banner inner={
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  Inicio
                </Link>
                <Link color="inherit" to="/blog">
                  Blog
                </Link>
              </Breadcrumbs>
              }
              name = {this.state.post.title}
              bannerImage = {'top-dettaglio-grace-karboxx.jpg'}
            />
            <Container maxWidth="lg" className="mt-5">
              <Grid container>
                <Grid item xs={12} sm={7} >
                  <div className="info-note">
                    <Dynamic
                      post={this.state.post}/>
                  </div>
                </Grid>
                <Grid item xs={12} sm={5} >
                  <div>
                    <OtherNotes/>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        ))}
        
        <Info  />
        <Footer />

      </div>
    );
  }
}

export default withRouter(Note);