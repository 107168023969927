const dev = {
  URL: {
    SERVER: "http://localhost:51/api",
    BASE: "http://localhost:51"
  }
};

const prod = {
  URL: {
    SERVER: "https://quadrifoglio.com.mx/api",
    BASE: "https://quadrifoglio.com.mx"
  }
};

let config = '';
if (process.env.REACT_APP_STAGE === 'prod') {
  config = prod
} else {
  config = dev
}

global.config = config;
