import React, { Component } from 'react';
import { Admin, Resource, fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import PostList from './../../PagesComponents/Admin/posts/posts';
import { PostCreate } from './../../PagesComponents/Admin/posts/create';
import { PostEdit } from './../../PagesComponents/Admin/posts/edit';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = JSON.parse(localStorage.getItem('auth')).access_token;
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider(`${global.config.URL.SERVER}`, httpClient);

const authProvider = {
  // authentication
  login: ({ username, password }) =>  {
    const request = new Request(`${global.config.URL.SERVER}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(auth => {
            localStorage.setItem('auth', JSON.stringify(auth));
        })
        .catch(() => {
            throw new Error('Network error')
        });
},
  checkError: error => Promise.resolve(),
  checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject(),
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  // authorization
  getPermissions: params => Promise.resolve(),
};
class AdminDashboard extends Component {

  componentWillMount() {
    document.body.style.overflow = "auto";
  }

  render() {
    return (
      <div>
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          >
          <Resource
            name="posts"
            options={{ label: 'Posts' }}
            list={PostList}
            create={PostCreate}
            edit={PostEdit}
            ></Resource>
        </Admin>
      </div>
    );
  }
}

export default AdminDashboard;