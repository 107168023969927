import React, { Component } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import CopyToClipboard from 'react-copy-to-clipboard';
import CircularProgress from '@material-ui/core/CircularProgress';

import './uploadFiles.scss';

class UploadImages extends Component {

  state = {
    openSnackbar: false,
    open: false,
    scroll: 'paper',
    images: [],
    loadData: false
  }

  componentDidMount() {
    this.getAllImages();
  }

  handleClickOpen() {
    this.setState({
      open: true
    });
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  uploadImages(data) {
    return axios({
      url: `${global.config.URL.SERVER}/uploadFiles`,
      method: 'post',
      data: data
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData();
    for (let index = 0; index < event.target[0].files.length; index++) {
      data.append('uploads[]', event.target[0].files[index]);
    }
    data.append('name', event.target[1].value);
    this.setState({
      loadData: true
    });
    this.uploadImages(data)
      .then(data => {
        console.log(data);
        this.getAllImages();
        this.setState({
          loadData: false
        });
      })
      .catch( err => {
        console.log(err);
      });
    event.target.reset();
  }

  getAllImages() {
    axios({
      url: `${global.config.URL.SERVER}/uploadFiles`,
      method: 'GET'
    })
      .then(result => result.data)
      .then(data => {
        console.log(data);
        this.setState({
          images: data.map(img => ({ ...img, fileName: `${img.name}.${img.extension}`}))
        });
      })
      .catch( err => {
        console.log(err);
      });
  }

  handleOpenSnackbar() {
    this.setState({
      openSnackbar: true
    })
  }

  handleCloseSnackbar() {
    this.setState({
      openSnackbar: false
    });
  }

  render() {
    return (
      <div>
        <Button onClick={this.handleClickOpen.bind(this)}>Cargar imagenes</Button>

        <Dialog
          className="save-images"
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Cargar imagenes</DialogTitle>
          <DialogContent dividers={this.state.scroll === 'paper'}>
              
            <form onSubmit={this.handleSubmit.bind(this)} style={{marginBottom: '10px'}}>
              <input
                accept="image/*"
                multiple
                type="file"
              />
              <br/>
              <br/>
              <TextField label="Nombre" className="w-100" />
              <br/>
              <br/>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Guardar imagen
              </Button>
            </form>
            <Divider />
            <div className="allImages">
              {this.state.images.map((value, index) => {
                return(
                  <div className="image-uploaded" key={index}>
                    <div className="container-copy-image" onClick={this.handleOpenSnackbar.bind(this)}>
                      <img src={`${global.config.URL.BASE}/uploadFiles/${value.fileName}`} alt=""/>
                      <div className="title">{value.name}</div>
                      <div className="btn-images">
                        <div className="btn-images-note">
                          <CopyToClipboard text={`<div className="container-img"><img src="${global.config.URL.BASE}/uploadFiles/${value.fileName}" /></div>`}>
                            <div>
                              + Nota
                            </div>
                          </CopyToClipboard>
                        </div>
                        <div className="btn-images-note">
                          <CopyToClipboard text={`${global.config.URL.BASE}/uploadFiles/${value.fileName}`}>
                            <div>
                              Compartir
                            </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              key={`${'bottom'},${'center'}`}
              open={this.state.openSnackbar}
              onClose={this.handleCloseSnackbar.bind(this)}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<div id="message-id">URL copiada</div>}
            />

          <div>
            <div className={`circularProgress ${this.state.loadData? 'activated': ''}`}>
              <CircularProgress />
            </div>
          </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UploadImages;
