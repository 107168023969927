import { Component } from 'react';

import Menu from '../../PagesComponents/Menu/Menu';
import Footer from '../../PagesComponents/Footer/Footer';
import Info from '../../PagesComponents/Info/Info';

import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import SostenibilidadCertSection from './../../PagesComponents/Pages/SostenibilidadCertSection/SostenibilidadCertSection';
import {
  Link, withRouter
} from "react-router-dom";

import './SostenibilidadCert.scss';

class SostenibilidadCert extends Component {

  render() {
    return (
      <div className="SostenibilidadCert">

        <Menu />

        <Banner
          bannerImage={'top-dettaglio-x10-quadrifoglio.jpg'}
          name={'SOSTENIBILIDAD Y CERTIFICADOS'}
          inner={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/">
                Inicio
              </Link>
              <Link color="inherit" to="/quadrifoglio">
                Sostenibilidad y certificados
              </Link>
            </Breadcrumbs>
            }  
          />
        <SostenibilidadCertSection/>

        <Info  />
        <Footer />
      </div>
    );
  }
}

export default withRouter(SostenibilidadCert);